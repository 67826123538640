@charset "utf-8";

// Import a Google Font
// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700;800;900&family=Permanent+Marker&family=Raleway:wght@300;400;500&display=swap');

// $family-sans-serif: Raleway, BlinkMacSystemFont,-apple-system,"Segoe UI", Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
// $family-monospace:  Raleway, BlinkMacSystemFont, -apple-system, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;


$orange: #faae2b;
//$turquoise: #ff8e3c;

$white-bis: hsl(0, 0%, 98%);

$scheme-main-bis: #eff5fb;

// $white: #f2f7f5;

//$background: $white;
$body-background-color: #f2f7f5;

$link: #0675c1;

//$text: #55555a;
$text: #333;
// $body-weight: 500;

// $footer-background-color: #ffa8ba;
$link-hover: #faae2b;

// App var
$input-focus-color: $white-bis;
$form-link-color: #00473e;

$blockbox-bgcolor: white;
$section-alt-bgcolor: #fffffe;

// Dashboard specific overide
$dashboard-background-color: #f2f7f5;

$logo_w: 56px;
$logo_h: 56px;
