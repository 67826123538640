$mx1-top: 30.21%;
$mx1-left: 48.8%;

$mx2-top: 25%;
$mx2-left: 51%;

@keyframes buttonTransition {
  from {
    transform: scale(0.2);
  }

  to {
    transform: scale(1.5);
  }
}

@keyframes path1 {
  100% {
    top: $mx1-top; left: $mx1-left; 
  }
}

@keyframes path2 {
  100% {
   top: $mx2-top; left: $mx2-left;
  }
}

@keyframes yAxis {
  100% {
    animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
    transform: {
      top: $mx1-top;
      left: $mx1-left;
    }
  }
}

@keyframes xAxis {
  50% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    transform: translateX(300px);
  }
}

.innerCircle {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: buttonTransition;
  transform-origin: center center;

  transition: opacity 0.3s ease-in-out;
}

.innerCircle2 {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: buttonTransition;
  transform-origin: center center;

  transition: opacity 0.5s ease-in-out;
}

.mx-dot {
  position: absolute;

  width: .6rem;
  height: .6rem;
  border-radius: 50%;

  border-style: solid;
  border-width: 0px;
  border-color: #F2778A;

  margin: 0 0.5rem;

  box-shadow: 0 0 0 0.25em #eb720d;

  @extend .innerCircle;
}

.mx-dot1 {
  top: $mx1-top;
  left: $mx1-left;
}

.mx-dot2 {
  top: $mx2-top;
  left: $mx2-left;
  @extend .innerCircle2;
}


.src-dot {
  box-shadow: 0 0 0 0.1em #eb720d;
  border-radius: 0;
  width: 0.4rem;;
  height: 0.4rem;;
}

.src-dot1 {
  @extend .mx-dot;
  @extend .src-dot;

  animation: path1 2.5s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
}

.src-dot1::after {
  @extend .mx-dot;
  @extend .src-dot;

  animation: path1 2.5s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
}

.src-dot2 {
  @extend .mx-dot;
  @extend .src-dot;
  animation: path2 2.5s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
}

.src-dot2::after {
  @extend .mx-dot;
  @extend .src-dot;
  animation: path2 2.5s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
}


.hero {
  //padding: 2.5rem 0 2.5rem 0;
  padding: 0;

  .hero-body {
  }

  // background-color: #FBAB7E;
  // background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 92%);
  // background: top left url("/artworks/overlay.jpeg");
  background: top center url("../images/landing/p3.svg");
  //background-size: 1000px 750px;

  .worldmap {
    position: relative;

    img {
      background-color: transparent;
    }
  }

  h1, h2 {
    color: #39444d;
    //color: #fbfaf9;
  }

  .hero-first {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  .hero-two {
    font-size: 1.5rem;
    //font-family: 'Permanent Marker', cursive;

    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    strong {
      color: #363636;
      font-weight: 700;
      font-size: 2.25rem;
      padding-left: 0.5rem;

      @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

  }

  a {
    color: #353535;
    // color: #fff;

    margin-top: 0.2rem;
    display: inline-block;
    font-size: 2.25rem;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }

    &.fun:hover {
    }

    &.fast:hover {
    }
  }

  .points {
    display: block;
    margin: 1rem;
    font-weight: bold;
    font-size: 1.1rem;
    color: #353535;
    //color: #fff;

    span {
      display: inline-block;
      margin: 1rem;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  h1 span {
    display: block;
    font-size: 40px;
    letter-spacing: -0.5px;
  }

  .email-count {
    margin: 22px 0;
    font-size: 18px;
  }
}

.fast-reliable {
  text-align: center;
  background: #fff;
}

.partner {
  text-align: center;
  padding: 3rem 0 2rem 0;

  .partner-list {
    padding-bottom: 1.5rem;
  }

  .partner-list a {
    display: inline-block;
    margin: 0 15px;
    position: relative;
    vertical-align: middle;
    width: 40px;
    height: auto;
  }

  .why-pa {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 300;
  }
}

// Feature
.features {
  background: #fff;

  .feature {
    .image {
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      left: 0;
      top: 0.25rem;
    }

    border-radius: 6px;
    color: #333;
    display: block;
    padding: 0 0 0 3rem;
    margin-bottom: 1rem;
    position: relative;
    transition-duration: 86ms;

    &-title {
      color: #363636;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.25;
      margin-bottom: .25em;
      position: relative;
    }
  }
}


.promise {
  // https://hexcolor.co/gradient-colors
  background: linear-gradient(135deg, #90F7EC 10%, #32CCBC 100%);

  .title {
    margin-bottom: 3rem;
  }
}
