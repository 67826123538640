.is-sticky {
  position: fixed;
}

.menu-active {
  border-radius: 0;

  background-repeat: no-repeat;
  background-position: 0 95%;

  //background-image: linear-gradient(120deg, #FEE48F 0%, #F88C8A 100%);
  background-color: #fdedf6;
  background-size: 100% 0.25rem;
  transition: background-size 0.25s ease-in, background-position 0.25s ease-in;
}


.hue {
  display: inline-block;

  width: .6rem;
  height: .6rem;
  border-radius: 50%;

  border-style: solid;
  border-width: 0px;
  border-color: #F2778A;

  margin: 0 0.5rem;
}

.hue-borderless {
  border-width: 0;
}

.hue-active {
  background-color: $green;
}
.hue-inactive {
  background-color: $red;
}


.invisible {
  display: none !important;
}


.section-alt {
  background-color: $section-alt-bgcolor;
}


.header {
  border-bottom: 1px solid #eee;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  position: relative;
}

.header .navbar-menu a {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 .25rem;
}

.header .user-dropdown {
  left: -7rem;
}


.navbar.is-spaced {
  padding: .8rem 2rem;
}


.anim-link {
  background-repeat: no-repeat;
  background-position: 0 95%;

  background-image: linear-gradient(120deg, #f0f0ea 0%, #f0f0ea 100%);

  background-size: 15% 1px;
  transition: background-size 0.25s ease-in, background-position 0.25s ease-in;

  &:hover {
    transition: background-size 0.25s ease-in, background-position 0.25s ease-in;
    background-image: linear-gradient(135deg, #FCCF31 10%, #F55555 100%);
    background-size: 100% 0.12rem;
  }
}


.footer {
  background-color: #f9f9f7;

  margin-top: 2rem;
  text-align: center;
  //font-size: 1.2rem;
  box-shadow: 0px -1px 0px 0px #eaeaea;

  .link-item {
    display: inline-block;
    margin: 0 .5rem;
    padding: 0.25rem 0;
    font-size: 1.1rem;

    &:hover {
      background-repeat: no-repeat;
      background-position: 0 95%;

      //background-image: linear-gradient(135deg, #FCCF31 10%, #F55555 100%);
      background-image: linear-gradient(120deg, #f0f0ea 0%, #f0f0ea 100%);

      background-size: 100% 0.12rem;
      transition: background-size 0.15s ease-in, background-position 0.15s ease-in;
    }
  }

  .link-title {
    color: #363636;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.5rem 0;

    a {
      color: #363636;
    }
  }
}


.input:focus {
  background: $input-focus-color;
}

.app-container {
}

.form-link {
  margin-top: 3rem;
  font-size: 1.2rem;
  a {
    color: $form-link-color;
  }
}

.devise-link a {
  margin: 0.5rem 0;
  display: block;
}

.blockbox {
  background: $blockbox-bgcolor;
  padding: 2.5rem;
  border-radius: 3px;
}

#logo {
  margin-right: 1rem;
  padding-top: 0;
  padding-bottom: 0;

  img {
    width: $logo_w;
    height: $logo_h;
    max-height: $logo_h;
  }
}

.navbar-avatar {
  background: #e0f1ff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #333; 

  .dropdown-trigger
  {
    width: 100%;
    text-align: center;
    padding-top: 8px;
  }

  .navbar-avatar-name {
    font-weight: 600;
    font-size: 1.4rem;
    color: #5b92ab;
    margin-left: -8px;
  }

  .icon {
    font-size: .8rem;
    font-weight: 300;
    color: #9eafbd;
    position: absolute;
    top: 16px;
    right: 8px;
  }
}



// Copy to clipboard icon
pre .copy-to-clipboard {
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: white;
  color: #ccc;
  border-radius: 2px;
}
code + .copy-to-clipboard {
  margin-left: -1px;
  border-left: 0 !important;
  font-size: inherit !important;
  vertical-align: middle;
  height: 24px;
  top: 0;
}
.copy-to-clipboard {
  background-image: url(../images/icon/clippy.svg);
  background-position: 50% 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  width: 27px;
  height: 1.45rem;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #586e75;
  background-color: #FFF7DD;
  margin-left: -.2rem;
  cursor: pointer;
  border-radius: 0 2px 2px 0;
  margin-bottom: 1px;
}


.faq {

  background: #fff;

  h1 {
    text-transform: capitalize;
    border-bottom: 2px solid #e4e4e4;
    padding-bottom: 1rem;
  }
  .faq-sidebar {

    .menu-list a.is-active {
      font-weight: 800;
      background: #fafafa;
      color: #faae2b;
    }
    .menu-list a:hover {
      background-color: #fafafa;
      color: #faae2b;
    }

  }

  .faq-content {
    padding-left: 3rem;
    border-left: 1px solid #e4e4e4;

    .question p {
      padding-bottom: 2rem;
    }


    img {
      border-radius: 6px;
      margin-top: 1rem;
    }
  }

  .docs-content {
    ol {
      list-style-position: inside;

      li {
        padding: .5rem 0;
      }
    }
  }

}

.changelog {

  p {
    margin: 1rem 0;
    line-height: 1.7;
  }

  ul {
    li {
      list-style-type: disc;
      padding: .2rem 0;
      list-style-position: inside;
    }
  }
}

.highlight pre {
  background-color: #1f2330;
  color: #d9e0e8;
  font-size: 1em;
  line-height: 1.375;
  margin: 0!important;
  padding: 1.25em 1.5em;
  white-space: pre;
  word-break: break-word;
}

pre code {
    background-color: transparent;
    color: currentColor;
    font-size: 1em;
    padding: 0;
}
