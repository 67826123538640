.domain-detail {
  .avatar {
    width: 32px;
    height: 32px;
  }

  input.input {
    font-weight: 500;
  }

  td {
    vertical-align: middle;
  }

  .domain-detail-avatar {
    width: 50px;
    vertical-align: middle;
    padding: 0 0.5rem 0 1.5rem
  }

  .domain-detail-status  {
    padding-left: 1rem;
    vertical-align: middle;

    span {
      font-size: 1rem;
    }
  }

  .domain-action, .domain-link-name {
    margin: .8rem 0;
  }

  .domain-action {
    @extend .has-text-right;
  }

  .domain-link-name {
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.2rem !important;
  }
}

.pagination .page a {
  @extend .pagination-link;
}

.pagination .prev a{
  @extend .pagination-previous;
}
.pagination .next a{
  @extend .pagination-next;
}

.pagination .page.active a{
  @extend .pagination-link;
  @extend .is-current;
}


.domain-list {
  border-radius: 6px;
  border: 2px solid #eee;
}

.dashboard-guide {

}


.alias {
  padding: 1.5rem;

  .form-label {
    padding: 0 0 0.3rem 0;
    color: #444;
  }

  .forward {
    text-align: center;
    padding: 35px 0 0 0;
  }

  .forward-action  .btn {
    margin-top: 30px;
  }

  .field-label {
    text-align: center;
    margin: 0;

    .icon {
      width: 2.5rem;
      height: 2.5rem;
    }

  }

  .alias-new-content {
    .title {
      padding-top: 1rem;
      margin-top: 2rem;
      border-top: 1px solid #eee;
    }
  }

  .alias-cell {
    margin-bottom: 1rem;
  }
}

.domain-indicator {
  padding-bottom: 0.5rem;
  //margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  color: #00947e;

  .domain-name {
    font-size: 1.8rem;
    margin: 0 0.3rem;
  }

  .tag {
    margin-top: 0.5rem;
  }
}

.maillog {
  .content {
    overflow-wrap: break-word;
  }

  .mailrow {
    display: block;
    font-size: 1rem;
    color: #F6416C;
    cursor: pointer;

    padding: .5rem 0 .5rem 0.5rem;

    background-repeat: no-repeat;
    background-position: 0 95%;
    background-size: 100% .25rem;
    background-image: linear-gradient(120deg, #f0f0ea 0%, #f0f0ea 100%);

    &.is-full {
      max-width: 100%;
    }
  }

  .mailrow:hover {
    background: #E2EBF0;
  }

  .mailrow.is-active {
    background: #f5f5f5;
  }

  .meta-info {
    overflow-wrap: break-word;
    padding-left: 3rem;
    color: #536976;

    .date {
      font-size: 0.8rem;
    }

    .from, .to {
      font-weight: bold;
      font-size: 0.9rem;
      overflow-wrap: break-word;
    }
  }

  .is-full {
    .meta-info {
      max-width: 100%;
    }
    .meta-subject {
      max-width: 100%;
    }
  }

  .meta-subject {
    font-size: 1rem;
    overflow-wrap: break-word;
    //color: #F6416C;
    color: #0083B0;
    font-weight: bold;
  }
}

.smtp-account {
  .password {
    font-size: 1.3em;
    border: 1px dashed #ffe270;
    border-color: #ffe270;

  }
}
